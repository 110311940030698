import { explode, supportsCookies } from "../utils";
import Cookie from "js-cookie";

/**
 * Get current user decision from the cookie value in a minimal form.
 */
function getUserDecision(cookieName) {
  const cookieValue = Cookie.get(cookieName);

  if (!cookieValue) {
    const supportsCookiesPrefix = cookieName.split("-")[0];

    if (!supportsCookies(supportsCookiesPrefix ? "".concat(supportsCookiesPrefix, "-test") : undefined)) {
      // Use our `window` object instead of cookie value
      return window.rcbDisabledCookieComp || false;
    }

    return false;
  } // Validate cookie length


  const split = explode(cookieValue, ":", 3);

  if (split.length !== 3) {
    return false;
  }

  const [uuidString, revision] = split;
  const uuids = uuidString.split(",");
  const uuid = uuids.shift();
  const consent = JSON.parse(split[2]);
  return {
    uuid,
    previousUuids: uuids,
    revision,
    consent
  };
}

export { getUserDecision };