import { OPT_IN_EVENT, OPT_OUT_EVENT } from "..";
import { consentSync } from ".";
/**
 * `window.consentApi.consent`: Check if a given technical information (e.g. HTTP Cookie, LocalStorage, ...) has a consent:
 *
 * - When a technical information exists in defined cookies, the Promise is only resolved after given consent
 * - When no technical information exists, the Promise is immediate resolved
 *
 * Example (ES5):
 * ```js
 * (window.consentApi && window.consentApi.consent("http", "_twitter_sess", ".twitter.com") || Promise.resolve()).then(function() {
 *     console.log("Consent for Twitter embed given, do something...!");
 * });
 * ```
 *
 * Example (ES6, Babel, TS):
 * ```js
 * (window.consentApi?.consent("http", "_twitter_sess", ".twitter.com") || Promise.resolve()).then(() => {
 *     console.log("Consent for Twitter embed given, do something...!");
 * });
 * ```
 *
 * **Since 2.3.0**: You can also check for consent by cookie ID (ID in `wp_posts`, post id):
 *
 * ```js
 * window.consentApi.consent(15)
 * ```
 */

function consent(...args) {
  return new Promise((resolve, reject) => {
    const {
      cookie: foundCookie,
      consentGiven,
      cookieOptIn
    } = consentSync(...args);

    if (foundCookie) {
      if (consentGiven) {
        if (cookieOptIn) {
          resolve();
        } else {
          reject();
        }
      } else {
        // Wait for consent
        document.addEventListener(OPT_IN_EVENT, async ({
          detail: {
            service
          }
        }) => {
          if (service === foundCookie) {
            resolve();
          }
        }); // Wait for opt out

        document.addEventListener(OPT_OUT_EVENT, async ({
          detail: {
            service
          }
        }) => {
          if (service === foundCookie) {
            reject();
          }
        });
      }
    } else {
      resolve();
    }
  });
}

export { consent };