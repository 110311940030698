var RouteHttpVerb;

(function (RouteHttpVerb) {
  RouteHttpVerb["GET"] = "GET";
  RouteHttpVerb["POST"] = "POST";
  RouteHttpVerb["PUT"] = "PUT";
  RouteHttpVerb["DELETE"] = "DELETE";
  RouteHttpVerb["PATCH"] = "PATCH";
})(RouteHttpVerb || (RouteHttpVerb = {}));

export { RouteHttpVerb };